<template>
  <div class="ibox" ref="shopsConfiguration">
    <div class="ibox-title">
      <h4>Shops configuration</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content" style="display:none">
      <form id="config-shops-form" name="config-shops-form" role="form" action="#" method="post" @submit="onFormSubmit">
        <div class="form-group">
            <label for="stagingDB">{{ $t('shop.configuration.stagingDB') }}</label>
            <div>
              <input id="stagingDB" type="text" class="form-control" :placeholder="$t('shop.configuration.stagingDB_placeholder')" v-model="form.stagingDB" />
            </div>
        </div>

        <div class="form-group">
            <label for="productionDB">{{ $t('shop.configuration.productionDB') }}</label>
            <div>
              <input id="productionDB" type="text" class="form-control" :placeholder="$t('shop.configuration.productionDB_placeholder')" v-model="form.productionDB" />
            </div>
        </div>

        <button type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('configuration.form.button') }}</button>
      </form>
    </div>
  </div>
</template>

<style scoped>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

@Component({
  components: { }
})
export default class Shops extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly config!: any

  form = {
    stagingDB : this.config.admin["staging-db"],
    productionDB : this.config.admin["production-db"]
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=config-shops-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  
    enableIbox(this.$refs.shopsConfiguration as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.shopsConfiguration as HTMLElement);
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();

    var input = {
      ...this.form,
    }
    
    api.postAPI('/api/config/shop/update', input, options).then((response:any) => {
      this.laddaSubmit!.stop();
    });
  }

}
</script>